<template>
  <div :class="[interactive ? 'cursor-pointer' : 'cursor-move']">
    <Header
      v-if='headersection'
      @room='enterRoom($event)'
      @playvideo='playVideo($event)'
      @impressum='openGeneralImpressum()'
      @externallink='externalLink($event)'
      :isExpoOpen='authenticated'
      :headersection='headersection'
    />
    <CursorPosition v-if='showCoords' :pos='cursorpos' />
    <transition name='home'>
      <img
        v-if='
          (!authenticated || !loaded || $store.state.room1.showIntro) &&
          !devmode
        '
        class='fixed w-full h-full'
        :src='$cmsFile($store.state.room1.roomData?.loading_img.content[0].src)'
        alt='Introscreen for login showing a preview of the virtual exhibition'
      />
    </transition>
    <transition name='home'>
      <div v-if="authmethod === 'password' && !authenticated" class='intro'>
        <div
          class='bg-fcn-blue p-8 px-48 text-white flex flex-col items-center border-2 border-fcn-orange-light rounded-lg'
        >
          <img class='mt-2 mb-4 w-42' src='/assets/img/icons/fcn.svg' />
          <h1 class='text-3xl uppercase'>
            Virtuelle<br />Kommunikationsplattform
          </h1>
          <div class='mt-8 text-xl'>
            Bitte geben Sie Ihr Passwort ein. <br />
            <input
              class='border-4 rounded-lg m-4 p-2 border-fcn-orange-light bg-fcn-blue w-64'
              type='password'
              v-model='passcode'
            />
            <div class='text-3xl'>Kein Passwort?</div>
            <div class='mt-2 text-xl'>
              Dann kontaktieren Sie uns unter
              <a href='mailto:info@frontcooking-network.de' class='underline'
              >info@frontcooking-network.de </a
              ><br />
              um einen Besuchstermin zu vereinbaren.
            </div>
          </div>
          <p class='text-fcn-orange text-sm -mt-2'>{{ errormessage?.de }}</p>
          <button
            :class="[
              !hasPasscode
                ? 'bg-transparent cursor-not-allowed text-transparent mt-0'
                : 'bg-fcn-orange mt-4 text-white',
            ]"
            class='fcn-btn uppercase mt-8'
            @click='login'
          >
            Weiter
          </button>
        </div>
      </div>
    </transition>
    <transition name='home'>
      <div v-if="authmethod === 'register' && !authenticated" class='intro'>
        <div
          class='bg-fcn-blue pb-4 mt-8 px-48 text-white flex flex-col items-center border-2 border-fcn-orange-light rounded-lg'
        >
          <img class='mt-4 mb-4 w-42' src='/assets/img/icons/fcn.svg' />
          <h1 class='text-3xl uppercase'>
            Virtuelle<br />Kommunikationsplattform
          </h1>
          <div class='mt-8'>
            Bitte registrieren Sie sich, um einzutreten. <br />
            <div
              class='grid grid-cols-3 gap-x-2 gap-y-4 m-4 max-w-lg items-center p-8 border-2 border-fcn-orange-light rounded-lg'
            >
              <div class='col-span-1'>
                Email<sup class='text-fcn-orange font-bold'>*</sup>
              </div>
              <input
                class='border-2 rounded-lg border-fcn-orange-light bg-fcn-blue p-2 col-span-2'
                type='email'
                v-model='userdata.email'
                required
                autocomplete='email'
              />
              <div class='col-span-1'>
                Vorname<sup class='text-fcn-orange font-bold'>*</sup>
              </div>
              <input
                class='border-2 rounded-lg border-fcn-orange-light bg-fcn-blue p-2 col-span-2'
                type='text'
                v-model='userdata.firstname'
                required
                autocomplete='given-name'
              />
              <div class='col-span-1'>
                Nachname<sup class='text-fcn-orange font-bold'>*</sup>
              </div>
              <input
                class='border-2 rounded-lg border-fcn-orange-light bg-fcn-blue p-2 col-span-2'
                type='text'
                v-model='userdata.lastname'
                required
                autocomplete='family-name'
              />
              <div class='col-span-1'>Firma</div>
              <input
                class='border-2 rounded-lg border-fcn-orange-light bg-fcn-blue p-2 col-span-2'
                type='text'
                v-model='userdata.company'
                autocomplete='company'
              />
              <div class='col-span-1'>Telefon</div>
              <input
                class='border-2 rounded-lg border-fcn-orange-light bg-fcn-blue p-2 col-span-2'
                type='tel'
                v-model='userdata.phone'
                autocomplete='phone'
              />
              <div
                class='col-span-3 text-justify text-xs cursor-pointer leading-tight'
                @click='userdata.legal = !userdata.legal'
              >
                <input
                  type='checkbox'
                  required
                  v-model='userdata.legal'
                  class='self-start justify-self-end cursor-pointer'
                />
                Hiermit bestätige ich, die Hinweise zum Datenschutz gelesen und
                akzeptiert zu haben. Ich willige ein, dass die über Kontakt- und
                Email-Formulare, Registrierungen und Logins gesammelten Daten
                zur fortlaufenden Verbesserung der Seite, zu anonymen
                Statistikzwecken oder unterstützenden Informationen für die
                Nutzung unserer Seite genutzt werden. Als Nutzer kann dem
                jederzeit per E-Mail an
                <a
                  class='underline whitespace-nowrap'
                  href='mailto:info@frontcooking-network.de'
                  target='_blank'
                >info@frontcooking-network.de</a
                >
                widersprochen werden. Weitere Infos finden Sie in unserer
                <a
                  class='underline'
                  target='_blank'
                  href='https://www.frontcooking-network.de/datenschutzerklaerung/'
                >Datenschutzerklärung</a
                >
                und im
                <a
                  class='underline'
                  target='_blank'
                  @click='openGeneralImpressum()'
                >Impressum</a
                >.
                <sup class='text-fcn-orange font-bold'>*</sup>
              </div>
              <div class='text-xs col-span-3 justify-self-end font-thin'>
                <sup class='text-fcn-orange font-bold'>*</sup>Pflichtfeld
              </div>
            </div>
          </div>
          <p class='text-fcn-orange text-sm -mt-2'>{{ errormessage?.de }}</p>
          <button
            :class="[
              !canRegister
                ? 'bg-transparent cursor-not-allowed text-transparent mt-0'
                : 'bg-fcn-orange mt-4 text-white',
            ]"
            class='fcn-btn uppercase'
            @click='register'
          >
            Weiter
          </button>
        </div>
      </div>
    </transition>
    <transition name='home'>
      <div
        v-if='$store.state.room1.showIntro && authenticated && !devmode'
        class='intro z-50 fixed'
      >
        <div
          class='bg-fcn-blue p-8 px-48 text-white flex flex-col items-center border-2 border-fcn-orange-light rounded-lg'
        >
          <img class='mt-2 mb-4 w-42' src='/assets/img/icons/fcn.svg' />
          <h1 class='text-3xl uppercase'>
            Virtuelle<br />Kommunikationsplattform
          </h1>
          <button
            class='fcn-btn disabled:cursor-not-allowed'
            :class="loadingPercentage < 100 ? 'text-fcn-blue' : 'text-white'"
            :style='`background: linear-gradient(to right, ${primaryColor},rgba(247, 94, 37, ${
              loadingPercentage < 100 ? 0 : 1
            }) ${loadingPercentage}%);`'
            :disabled='!loaded'
            @click='
            // this.$matomo && this.$matomo.rememberConsentGiven(),
                enterRoom(1, startRoomPos)
            '
          >
            <span v-show='!loaded' class=''
            >lädt...<span>{{ loadingPercentage }} %</span></span
            >
            <span v-show='loaded' class=''>EINTRETEN</span>
          </button>
        </div>
      </div>
    </transition>
    <transition name='home'>
      <div
        v-if='!$store.state.room1.showIntro && $store.state.index.showTutorial'
        class='intro z-50 fixed'
      >
        <div
          class='bg-fcn-blue p-8 px-48 text-white flex flex-col items-center border-2 border-fcn-orange-light rounded-lg w-1/2 space-y-4'
        >
          <ExitBtn @exit="$store.commit('index/hideTutorial')" />
          <img class='mt-2 mb-4 w-42' src='/assets/img/icons/fcn.svg' />
          <carousel :items-to-show='1' class='w-full' :autoplay='20000'>
            <slide v-for='tutorial in 6' :key='tutorial' class='flex flex-col'>
              <h1 class='text-3xl mb-2'>
                {{ tutorials[tutorial - 1].headline }}
              </h1>
              <h2 class='text-xl'>{{ tutorials[tutorial - 1].subline }}</h2>
              <Vue3Lottie
                class='-mt-24'
                :animationData='tutorials[tutorial - 1].animation'
                :height='400'
                :width='400'
              />
            </slide>

            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </div>
      </div>
    </transition>
    <!-- OVERLAYS -->
    <!-- General Video Overlay -->
    <transition name='home'>
      <div v-show='$store.state.index.generalVideo' class='intro'>
        <ExitBtn @exit='closeGeneralVideo()' />
        <video
          ref='generalvideo'
          class='w-full h-full'
          controls
          :src='$store.state.index.generalSrc'
          @ended='closeGeneralVideo()'
          @loadeddata='startGeneralVideo()'
        />
      </div>
    </transition>
    <!-- General Gallery Overlay -->
    <transition name='home'>
      <div v-if='$store.state.index.generalGallery' class='intro'>
        <ExitBtn @exit='closeGeneralGallery()' />
        <GeneralGallery :content='$store.state.index.generalImgs?.content' />
      </div>
    </transition>
    <!-- General Photogrid Overlay -->
    <transition name='home'>
      <div v-if='$store.state.index.generalPhotogrid' class='overlay'>
        <ExitBtn @exit='closeGeneralPhotogrid()' />
        <Photogrid
          :content='$store.state.index.generalPhotogridContent.content'
          :covermode='
            $store.state.index.generalPhotogridContent.options?.covermode
          '
          :padding='$store.state.index.generalPhotogridContent.options?.padding'
        />
      </div>
    </transition>
    <!-- General Filegrid -->
    <transition name='home'>
      <div v-if='$store.state.index.generalFilegrid' class='overlay'>
        <ExitBtn @exit='closeGeneralFilegrid()' />
        <Downloadgrid :content='$store.state.index.generalFilegridContent' />
      </div>
    </transition>
    <!-- General Videogrid -->
    <transition name='home'>
      <div v-if='$store.state.index.generalVideogrid' class='overlay'>
        <ExitBtn @exit='closeGeneralVideogrid()' />
        <Videogrid :content='$store.state.index.generalVideogridContent' />
      </div>
    </transition>
    <!-- General iFrame-->
    <transition name='home'>
      <div v-if='$store.state.index.generalIFrame' class='overlay'>
        <ExitBtn @exit='closeGeneralIFrame()' />
        <iframe
          :src='$store.state.index.generalIFrameContent.content[0].src'
          class='w-11/12 h-5/6'
          :frameborder='
            $store.state.index.generalIFrameContent.content[0].options
              ?.frameborder
          '
          :scrolling='
            $store.state.index.generalIFrameContent.content[0].options
              ?.scrolling
          '
        />
      </div>
    </transition>

    <!-- Impressum-->
    <transition name='home'>
      <div v-if='$store.state.index.generalImpressum' class='overlay'>
        <ExitBtn @exit='closeGeneralImpressum()' />
        <Impressum />
      </div>
    </transition>

    <!-- Room1 -->

    <!-- GTC Webinars --->
    <transition name='home'>
      <div v-if='$store.state.room1.showWebinars' class='overlay'>
        <ExitBtn @exit="closeOverlay('Webinars')" />
        <Linklist title='Live Webinare & Webcasts' src='webinars' />
      </div>
    </transition>

    <!-- Video Texture -->
    <video
      ref='gtcvideo'
      class='w-full h-full hidden'
      controls
      src='#placeholder#'
      @ended="closeVideo('gtcvideo', 'AboutVideo')"
      muted
      crossorigin='anonymous'
      autoplay
      loop
    />
    <!-- exitDialog Overlay -->
    <transition name='home'>
      <div class='overlay' v-if='$store.state.room1.showExitDialog'>
        <Dialogbox
          message='Wollen Sie die Messe wirklich verlassen?'
          btn1text='Messe verlassen'
          btn2text='Nein, zurück'
          @dialogfunction='
            externalLink(
              `${$store.state.room1.roomData?.exitlink.content[0].src}`
            )
          '
          @dialogclose="closeOverlay('showExitDialog')"
        />
      </div>
    </transition>
    <!-- Audio Player -->
    <audio ref='podcastplayer'>
      Your browser does not support the audio element.
    </audio>

    <!-- Room 3 -->
    <!-- Zoom Register Overlay -->
    <transition name='home'>
      <div v-if='$store.state.room3.showZoomRegister' class='overlay'>
        <ExitBtn @exit="closeOverlay('showZoomRegister')" />
        <Linklist title='Live Webinare & Webcasts' src='webinars' />
      </div>
    </transition>

    <!-- ######################## -->
    <!-- ######################## -->
    <!-- 3D -->
    <Renderer
      ref='renderer'
      antialias
      resize='window'
      :pointer='{ intersectRecursive: true }'
      orbitCtrl
      shadow
    >
      <Camera ref='camera' :position='{ x: -4, y: 1.6, z: 0 }' :fov='60' />
      <Scene ref='scene'>
      <Sphere
        ref="sky"
        :radius="500"
        :heightSegments="32"
        :widthSegments="64"
        :scale="{ x: -1, y: 1, z: 1 }"
      >
        <BasicMaterial ref="skytex" :props="{ side: 1 }" />
      </Sphere>
<!--        <AmbientLight-->
<!--          :position="{ x: 0, y: 0, z: 0 }"-->
<!--          color="#efebd8"-->
<!--          :intensity="quality !== 'low' ? 0.1 : 0.2"-->
<!--        />-->
        <Ring
          ref='cursor'
          :scale='{ x: 0.8, y: 0.8, z: 0.8 }'
          :rotation='{ x: -1.5707963, y: 0, z: 0 }'
          :innerRadius='0.8'
          :thetaSegments='64'
        >
          <BasicMaterial
            :color='primaryColor'
            :props='{ transparent: true, opacity: showCursor ? 0.75 : 0 }'
          >
          </BasicMaterial>
        </Ring>

        <PointLight
          ref='sun'
          :position='{ x: -8, y: 20, z: 0 }'
          color='#fefaee'
          :intensity="quality !== 'low' ? 0.8 : 0.5"
          :castShadow='false'
        />
        <HemisphereLight
          groundColor='#080820'
          color='#fffff9'
          :intensity="quality !== 'low' ? 1 : 0.5"
        />
        <!-- ######################## -->
        <!-- PROJECT FCA -->
        <!-- ######################## -->

        <!-- ######################## -->
        <!-- ROOM 1-->
        <!-- ######################## -->
        <roomsFCNLobby
          v-if="projectID === 'FCN'"
          ref='room1'
          @instanceMe='instanceMe'
          @navigateTo='navigateTo'
          @moveCursor='moveCursor'
          @playVideo='playVideo'
          @openOverlay='openOverlay'
          @openGeneralFilegrid='openGeneralFilegrid'
          @openGeneralIFrame='openGeneralIFrame'
          @openGeneralPhotogrid='openGeneralPhotogrid'
          @externalLink='externalLink'
          @enterRoom='enterRoom'
          @highlight='highlight'
          @orbitMe='orbitMe'
          :primaryColor='primaryColor'
          :currentRoom='currentRoom'
        />
        <!-- Room2 -->
        <roomsFCNFotoVideo
          v-if="projectID === 'FCN'"
          ref='room2'
          @instanceMe='instanceMe'
          @navigateTo='navigateTo'
          @moveCursor='moveCursor'
          @playVideo='playVideo'
          @openOverlay='openOverlay'
          @openGeneralFilegrid='openGeneralFilegrid'
          @openGeneralIFrame='openGeneralIFrame'
          @openGeneralPhotogrid='openGeneralPhotogrid'
          @openGeneralGallery='openGeneralGallery'
          @openGeneralVideogrid='openGeneralVideogrid'
          @externalLink='externalLink'
          @enterRoom='enterRoom'
          @highlight='highlight'
          :primaryColor='primaryColor'
          :currentRoom='currentRoom'
        />
        <!-- Room3 -->
        <roomsFCNShowroom
          v-if="projectID === 'FCN'"
          ref='room3'
          @instanceMe='instanceMe'
          @navigateTo='navigateTo'
          @moveCursor='moveCursor'
          @playVideo='playVideo'
          @openOverlay='openOverlay'
          @openGeneralFilegrid='openGeneralFilegrid'
          @openGeneralIFrame='openGeneralIFrame'
          @openGeneralPhotogrid='openGeneralPhotogrid'
          @openGeneralGallery='openGeneralGallery'
          @openGeneralVideogrid='openGeneralVideogrid'
          @externalLink='externalLink'
          @enterRoom='enterRoom'
          @highlight='highlight'
          :primaryColor='primaryColor'
          :currentRoom='currentRoom'
        />
        <!-- Room4 in Hubs-->
        <!-- Room5 -->
        <roomsFCNLearning
          v-if="projectID === 'FCN'"
          ref='room5'
          @instanceMe='instanceMe'
          @navigateTo='navigateTo'
          @moveCursor='moveCursor'
          @playVideo='playVideo'
          @openOverlay='openOverlay'
          @openGeneralFilegrid='openGeneralFilegrid'
          @openGeneralIFrame='openGeneralIFrame'
          @openGeneralPhotogrid='openGeneralPhotogrid'
          @openGeneralGallery='openGeneralGallery'
          @openGeneralVideogrid='openGeneralVideogrid'
          @externalLink='externalLink'
          @enterRoom='enterRoom'
          @highlight='highlight'
          :primaryColor='primaryColor'
          :currentRoom='currentRoom'
        />
        <!-- Room6 -->
        <roomsFCNKreativ
          v-if="projectID === 'FCN'"
          ref='room6'
          @instanceMe='instanceMe'
          @navigateTo='navigateTo'
          @moveCursor='moveCursor'
          @playVideo='playVideo'
          @openOverlay='openOverlay'
          @openGeneralFilegrid='openGeneralFilegrid'
          @openGeneralIFrame='openGeneralIFrame'
          @openGeneralPhotogrid='openGeneralPhotogrid'
          @openGeneralGallery='openGeneralGallery'
          @openGeneralVideogrid='openGeneralVideogrid'
          @externalLink='externalLink'
          @enterRoom='enterRoom'
          @highlight='highlight'
          :primaryColor='primaryColor'
          :currentRoom='currentRoom'
        />
        <!-- FCA -->
        <roomsFCA101
          v-if="projectID === 'FCA'"
          ref='room1'
          @instanceMe='instanceMe'
          @navigateTo='navigateTo'
          @exposeObject='exposeObject'
          @moveCursor='moveCursor'
          @playVideo='playVideo'
          @openOverlay='openOverlay'
          @openGeneralFilegrid='openGeneralFilegrid'
          @openGeneralIFrame='openGeneralIFrame'
          @openGeneralPhotogrid='openGeneralPhotogrid'
          @openGeneralGallery='openGeneralGallery'
          @openGeneralVideogrid='openGeneralVideogrid'
          @externalLink='externalLink'
          @enterRoom='enterRoom'
          @highlight='highlight'
          @orbitMe='orbitMe'
          :primaryColor='primaryColor'
          :currentRoom='currentRoom'
        />
        <roomsFCA102
          v-if="projectID === 'FCA'"
          ref='room2'
          @instanceMe='instanceMe'
          @navigateTo='navigateTo'
          @moveCursor='moveCursor'
          @playVideo='playVideo'
          @openOverlay='openOverlay'
          @openGeneralFilegrid='openGeneralFilegrid'
          @openGeneralIFrame='openGeneralIFrame'
          @openGeneralPhotogrid='openGeneralPhotogrid'
          @openGeneralGallery='openGeneralGallery'
          @openGeneralVideogrid='openGeneralVideogrid'
          @externalLink='externalLink'
          @enterRoom='enterRoom'
          @highlight='highlight'
          @orbitMe='orbitMe'
          :primaryColor='primaryColor'
          :currentRoom='currentRoom'
        />
        <!-- EMPTY -->
        <roomsEMPTYLobby
          v-if="projectID === 'EMPTY'"
          ref='roomEmpty'
          @instanceMe='instanceMe'
          @navigateTo='navigateTo'
          @moveCursor='moveCursor'
          @playVideo='playVideo'
          @openOverlay='openOverlay'
          @openGeneralFilegrid='openGeneralFilegrid'
          @openGeneralIFrame='openGeneralIFrame'
          @openGeneralPhotogrid='openGeneralPhotogrid'
          @openGeneralGallery='openGeneralGallery'
          @openGeneralVideogrid='openGeneralVideogrid'
          @externalLink='externalLink'
          @enterRoom='enterRoom'
          @highlight='highlight'
          @orbitMe='orbitMe'
          :primaryColor='primaryColor'
          :currentRoom='currentRoom'
        />
        <!-- GTC -->
        <roomsGTCLobby
          v-if="projectID === 'GTC'"
          ref='room1'
          @instanceMe='instanceMe'
          @navigateTo='navigateTo'
          @moveCursor='moveCursor'
          @playVideo='playVideo'
          @onClick='onClick'
          @openOverlay='openOverlay'
          @openGeneralFilegrid='openGeneralFilegrid'
          @openGeneralIFrame='openGeneralIFrame'
          @openGeneralPhotogrid='openGeneralPhotogrid'
          @openGeneralGallery='openGeneralGallery'
          @openGeneralVideogrid='openGeneralVideogrid'
          @externalLink='externalLink'
          @enterRoom='enterRoom'
          @highlight='highlight'
          @orbitMe='orbitMe'
          :primaryColor='primaryColor'
          :secondaryColor='secondaryColor'
          :currentRoom='currentRoom'
        />
      </Scene>
      <!-- <EffectComposer>
        <RenderPass />
        <UnrealBloomPass :strength="0.1" />
        <SMAAPass />
      </EffectComposer> -->
    </Renderer>
    <Contactbutton v-show='!$store.state.index.generalVideo' />
  </div>
</template>

<script>
import Contactbutton from './components/elements/Contactbutton.vue'
import Header from './components/Header.vue'
import CursorPosition from './components/helpers/CursorPosition.vue'
import {
  LinearToneMapping as ToneMapping,
  MathUtils,
  DefaultLoadingManager,
  VideoTexture,
  TextureLoader,
  sRGBEncoding,
  Vector3,
  Euler,
  HalfFloatType,
  PMREMGenerator,
  InstancedMesh,
  Object3D,
  DynamicDrawUsage,
  PCFSoftShadowMap as ShadowMap
} from 'three'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
import * as TWEEN from '@tweenjs/tween.js'
import { defineAsyncComponent } from 'vue'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { Vue3Lottie } from 'vue3-lottie'
import axios from 'axios'

import tutorial1 from '/public/assets/animation/tutorial/schritt1.json'
import tutorial2 from '/public/assets/animation/tutorial/schritt2.json'
import tutorial3 from '/public/assets/animation/tutorial/schritt3.json'
import tutorial4 from '/public/assets/animation/tutorial/schritt4.json'
import tutorial5 from '/public/assets/animation/tutorial/schritt5.json'
import tutorial6 from '/public/assets/animation/tutorial/schritt6.json'
import { Sphere } from 'troisjs'

export default {
  components: {
    Sphere,
    Contactbutton,
    Header,
    CursorPosition,
    roomsFCNLobby: defineAsyncComponent(() =>
      import('./components/rooms/FCN/1_Lobby.vue')
    ),
    roomsFCNFotoVideo: defineAsyncComponent(() =>
      import('./components/rooms/FCN/2_FotoVideo.vue')
    ),
    roomsFCNShowroom: defineAsyncComponent(() =>
      import('./components/rooms/FCN/3_Showroom.vue')
    ),
    roomsFCNLearning: defineAsyncComponent(() =>
      import('./components/rooms/FCN/5_Learning.vue')
    ),
    roomsFCNKreativ: defineAsyncComponent(() =>
      import('./components/rooms/FCN/6_Kreativ.vue')
    ),
    roomsFCA101: defineAsyncComponent(() =>
      import('./components/rooms/FCA/101.vue')
    ),
    roomsFCA102: defineAsyncComponent(() =>
      import('./components/rooms/FCA/102.vue')
    ),
    roomsEMPTYLobby: defineAsyncComponent(() =>
      import('./components/rooms/EMPTY/1_Lobby.vue')
    ),
    roomsGTCLobby: defineAsyncComponent(() =>
      import('./components/rooms/GTC/1_Lobby.vue')
    ),
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Vue3Lottie
  },
  data() {
    return {
      tutorials: [
        {
          headline: 'Sich auf der Plattform bewegen',
          subline: 'Gewünschte Position mit linker Maustaste anklicken',
          animation: tutorial1
        },
        {
          headline: 'Sich im Raum drehen',
          subline: 'Rechte Maustaste gedrückt halten und drehen',
          animation: tutorial2
        },
        {
          headline: 'Interaktionsflächen aktivieren',
          subline: 'Bei Einfärbung des Gegenstands die linke Maustaste drücken',
          animation: tutorial3
        },
        {
          headline: 'Den Raum wechseln',
          subline: 'Tür auswählen und linke Maustaste drücken',
          animation: tutorial4
        },
        {
          headline: 'Schneller Raumwechsel über Burger-Menü',
          subline: 'Gewünschten Raum in der Auswahlliste anklicken',
          animation: tutorial5
        },
        {
          headline: 'Kontaktmöglichkeiten',
          subline: 'Chat-, Telefon- oder Mail-Button anklicken',
          animation: tutorial6
        }
      ],
      tokens: [],
      showCoords: import.meta.env.SHOW_CURSOR_POS || false,
      devmode: import.meta.env.VITE_DEV_MODE || false,
      authmethod: false,
      authenticated: false,
      authChecked: false,
      passcode: null,
      userdata: {
        email: null,
        firstname: null,
        lastname: null,
        company: null,
        phone: null,
        legal: false
      },
      errormessage: null,
      loaded: false,
      lockCursor: false,
      showCursor: true,
      cursorpos: {},
      dragging: 0,
      orbiting: false,
      loadingPercentage: 0,
      controls: null,
      interactive: false,
      primaryColor: '#f75e25',
      secondaryColor: '#ffffff',
      currentRoom: 1,
      startRoomPos: JSON.parse(import.meta.env.VITE_START_ROOM_POS),
      projectID: import.meta.env.VITE_PROJECT_ID,
      userHeight: 1.8, // increased from 1.6
      headersection: null,
      objlist: [],
      environments: {},
      environmentsLoaded: false,
      envMap: [],
      instancepos: {},
      quality: import.meta.env.VITE_QUALITY || 'low',
      agendaimg: null
    }
  },
  created() {
    this.currentRoom = parseInt(import.meta.env.VITE_START_ROOM)
    this.tokens = JSON.parse(localStorage.getItem('aT_' + this.projectID)) || []
    // this.checkAuth()

    if (!localStorage.getItem('showntutorial')) {
      this.$store.commit('index/showTutorial')
      localStorage.setItem('showntutorial', true)
    }
  },
  beforeCreate() {
    //Load all roomdata
    axios
      .post(`${import.meta.env.VITE_AXIOS_BASEURL}/api/rs`, {
        project: import.meta.env.VITE_PROJECT_ID
      })
      .then((res) => {
        res.data.forEach((r) => {
          this.getRoomData(r)
        })
      })
    axios
      .get(`/assets/data/${import.meta.env.VITE_PROJECT_ID}/objlist.json`)
      .then((res) => {
        this.objlist = res.data
      })
      .catch((e) => {
        console.error(e)
      })
    axios
      .get(`/assets/data/${import.meta.env.VITE_PROJECT_ID}/instancing.json`)
      .then((res) => {
        this.instancepos = res.data
      })
      .catch((e) => {
        console.error(e)
      })
    axios
      .get(`/assets/data/${import.meta.env.VITE_PROJECT_ID}/headersection.json`)
      .then((res) => {
        this.headersection = res.data
      })
      .catch((e) => {
        console.error(e)
      })
    axios
      .get(`/assets/data/${import.meta.env.VITE_PROJECT_ID}/environments.json`)
      .then((res) => {
        this.environmentsLoaded = true
        this.environments = res.data
      })
      .catch((e) => {
        console.error(e)
      })
  },
  mounted() {
    // this.$matomo && this.$matomo.enableHeartBeatTimer(15)
    // this.$matomo && this.$matomo.trackPageView('Loading')
    this.$store.commit('index/loadregister')
    const renderer = this.$refs.renderer
    const camCtrl = renderer.three.cameraCtrl

    renderer.renderer.toneMapping = ToneMapping
    // renderer.renderer.toneMappingExposure = 1.2
    renderer.renderer.outputEncoding = sRGBEncoding
    renderer.renderer.shadowMap.enabled = true
    renderer.renderer.shadowMap.type = ShadowMap

    switch (this.quality) {
      case 'medium':
        this.$refs.sun.o3d.shadow.bias = -0.0001
        this.$refs.sun.o3d.shadow.mapSize.width = 512 * 4
        this.$refs.sun.o3d.shadow.mapSize.height = 512 * 4
        break
      case 'high':
        this.$refs.sun.o3d.shadow.bias = -0.0001
        this.$refs.sun.o3d.shadow.mapSize.width = 1024 * 4
        this.$refs.sun.o3d.shadow.mapSize.height = 1024 * 4
        break
      case 'ultra':
        this.$refs.sun.o3d.shadow.bias = -0.0001
        this.$refs.sun.o3d.shadow.mapSize.width = 2048 * 4
        this.$refs.sun.o3d.shadow.mapSize.height = 2048 * 4
        break
    }
    this.loadSceneEnvironment()
    //Initialize Orbit Camera
    camCtrl.enablePan = false
    camCtrl.enableZoom = false
    camCtrl.enableDamping = true
    camCtrl.dampingFactor = 0.07
    camCtrl.autoRotateSpeed = 1.25
    camCtrl.mouseButtons = {
      LEFT: 0,
      MIDDLE: null,
      RIGHT: 0
    }
    camCtrl.addEventListener('change', () => {
      if (this.dragging < 3) this.dragging += 1
    })
    camCtrl.update()

    //Render Loop
    renderer.onBeforeRender(() => {
      if (this.dragging > 0) this.dragging -= 1
      TWEEN.update()
      camCtrl.update()
    })

    //Loading Screen
    DefaultLoadingManager.onLoad = () => {
      this.loaded = true
    }
    DefaultLoadingManager.onProgress = (_url, itemsLoaded, itemsTotal) => {
      // if (this.devmode) console.log(_url, itemsTotal)
      this.loadingPercentage = Math.round(
        (itemsLoaded / import.meta.env.VITE_ITEMS_TO_LOAD) * 100
      )
    }

    // For now, we always start in room 1
    this.enterRoom(1, this.startRoomPos)
  },
  computed: {
    hasPasscode: function() {
      return !this.passcode ? false : true
    },
    canRegister: function() {
      return (
        !!this.userdata.email &&
        !!this.userdata.firstname &&
        !!this.userdata.lastname &&
        !!this.userdata.legal
      )
    }
  },
  methods: {
    checkAuth() {
      console.log('Checking auth...')
      window.degToRad = MathUtils.degToRad

      if (this.tokens && this.tokens[this.currentRoom]) {
        const data = {
          token: this.tokens[this.currentRoom],
          project: this.projectID,
          room: this.currentRoom
        }

        axios
          .post(`${import.meta.env.VITE_AXIOS_BASEURL}/api/auth/validate`, data)
          .then((res) => {
            if (res.data.valid) {
              this.authenticated = true
            } else {
              this.authenticated = false
              this.tokens.splice(this.currentRoom, 1)
              localStorage.setItem(
                'aT_' + this.projectID,
                JSON.stringify(this.tokens)
              )
              this.checkAuth()
            }
          })
      }

      axios
        .post(`${import.meta.env.VITE_AXIOS_BASEURL}/api/auth/method`, {
          project: this.projectID,
          room: this.currentRoom
        })
        .then((res) => {
          this.authChecked = true
          this.authmethod = res.data.authmethod

          if (this.authmethod === 'open') {
            console.log('Welcome Guest')
            this.authenticated = true
          } else if (this.authmethod === 'register') {
            this.authenticated = false

            if (this.$store.state.index.register) {
              this.userdata = this.$store.state.index.register
              this.register()
            }
          }
        })

      // if (!this.authenticated || !this.tokens[this.currentRoom]) {
      //   console.log('Checking room authentication...')
      //
      //   axios
      //     .post(`${import.meta.env.VITE_AXIOS_BASEURL}/api/auth/method`, {
      //       project: this.projectID,
      //       room: this.currentRoom,
      //     })
      //     .then((res) => {
      //       this.authmethod = res.data.authmethod
      //       if (res.data.authmethod === 'open') {
      //         console.log('Welcome Guest')
      //         this.authenticated = true
      //       } else {
      //         if (res.data.authmethod === 'register') {
      //           this.authenticated = false
      //           if (this.$store.state.index.register) {
      //             this.userdata = this.$store.state.index.register
      //             this.register()
      //           }
      //         }
      //       }
      //     })
      // }
    },
    login() {
      // this.$matomo && this.$matomo.rememberConsentGiven()
      axios
        .post(`${import.meta.env.VITE_AXIOS_BASEURL}/api/auth/login`, {
          password: this.passcode,
          project: this.projectID,
          room: this.currentRoom
        })
        .then((res) => {
          if (res.data.token) {
            this.authenticated = true
            this.tokens[this.currentRoom] = res.data.token
            localStorage.setItem(
              'aT_' + this.projectID,
              JSON.stringify(this.tokens)
            )
            this.enterRoom(this.currentRoom, this.startRoomPos)
          }
        })
        .catch((error) => {
          console.log(error)
          this.errormessage = error.data
        })
    },
    register() {
      // this.$matomo && this.$matomo.rememberConsentGiven()
      axios
        .post(`${import.meta.env.VITE_AXIOS_BASEURL}/api/auth/register`, {
          email: this.userdata.email,
          firstname: this.userdata.firstname,
          lastname: this.userdata.lastname,
          company: this.userdata.company,
          phone: this.userdata.phone,
          project: this.projectID,
          room: this.currentRoom
        })
        .then((res) => {
          if (res.data.token) {
            this.authenticated = true
            this.tokens[this.currentRoom] = res.data.token
            localStorage.setItem(
              'aT_' + this.projectID,
              JSON.stringify(this.tokens)
            )
            this.enterRoom(this.currentRoom, this.startRoomPos)
            this.$store.commit('index/saveregister', this.userdata)
          }
        })
        .catch(({ response }) => {
          this.errormessage = response.data
        })
    },
    async getRoomData(r) {
      axios
        .post(`${import.meta.env.VITE_AXIOS_BASEURL}/api/rc`, {
          project: this.projectID,
          room: r
        })
        .then((res) => {
          this.$store.commit(`room${r}/setRoomData`, res.data)
        })
    },
    instanceMe(gltf, positionname, count, groupname, scale = 1) {
      console.log('instanceMe gltf', gltf)
      console.log('instanceMe positionname', positionname)
      console.log('instanceMe count', count)
      console.log('instanceMe groupname', groupname)
      console.log('instanceMe scale', scale)

      const geo = gltf.scene.children[0].geometry.clone()
      const mat = gltf.scene.children[0].material
      let instanced = new InstancedMesh(geo, mat, count)

      instanced.instanceMatrix.setUsage(DynamicDrawUsage)
      const dummy = new Object3D()
      for (let i = 0; i < count; i++) {
        const dpos = this.instancepos[groupname][positionname][i]
        dummy.position.set(dpos[0], dpos[1], dpos[2])
        dummy.scale.set(scale, scale, scale)
        dummy.updateMatrix()
        instanced.setMatrixAt(i, dummy.matrix)
      }
      instanced.instanceMatrix.needsUpdate = true
      if (groupname) {
        this.$refs.scene.scene.children.forEach((e) => {
          if (e.name === groupname) e.add(instanced)
        })
      } else {
        this.$refs.scene.scene.add(instanced)
      }
    },
    loadEnvironment() {
      let loadEnvInterval = setInterval(() => {
        if (!this.environmentsLoaded) {
          return
        }

        clearInterval(loadEnvInterval)

        const sky = this.$refs.sky
        const curEnv = this.environments[this.currentRoom]
        console.log(sky, curEnv, this.currentRoom, this.environments)
        if (!this.envMap[this.currentRoom]) {
          this.envMap[this.currentRoom] = new TextureLoader().load(
            `assets/img/env/${curEnv.jpg}`
          )
        }

        sky.position.set = curEnv.pos
        const eul = new Euler(curEnv.rot.x, curEnv.rot.y, curEnv.rot.z)
        sky.o3d.setRotationFromEuler(eul)
        this.$refs.skytex.setTexture(this.envMap[this.currentRoom])
        this.$refs.skytex.needsUpdate = true
      }, 100)
    },
    loadSceneEnvironment() {
      const env = parseInt(import.meta.env.VITE_SHOW_ENVIRONMENT)
      /* console.log(env) */
      // if (env === 1) {
      //   console.log(env, 'loading_scene_environment')
      //   //Load Environment
      //   const loader = new RGBELoader().setDataType(HalfFloatType)
      //   const pmremGenerator = new PMREMGenerator(this.$refs.renderer.renderer)
      //
      //   loader.load(
      //     `assets/img/env/roof_garden_2k.hdr`,
      //     (texture) => {
      //       const envMap = pmremGenerator.fromEquirectangular(texture).texture
      //       pmremGenerator.dispose()
      //
      //       this.$refs.renderer.scene.environment = envMap
      //     },
      //     undefined,
      //     (err) => {
      //       console.error('An error occurred setting the environment')
      //     }
      //   )
      //   pmremGenerator.compileEquirectangularShader()
      // }
    },
    orbitMe(start, target, audio = false) {
      this.orbiting = true
      this.navigateTo(null, start)
      this.cameraLookAt(null, target, true)
      if (audio) this.playAudio('podcastplayer', audio)
    },
    highlight(element, isPointerOver, event) {
      // Adjusted by Chapter2 - no longer relies on set $refs. Just add a pointerOver event

      // @deprecated refsRoomElement is for backwards compatibility
      const refsRoomElement = this.$refs['room' + this.currentRoom]?.$refs[element]
      const toHighlight = refsRoomElement || event?.component

      if (toHighlight?.material) {
        toHighlight.material.opacity = isPointerOver ? 0.4 : 0
      }
    },
    loadVideoTexture(mesh, video) {
      const vidtex = new VideoTexture(video)
      mesh.setTexture(vidtex)
    },
    loadImageTexture(mesh, imagepath, external = false) {
      const imgtex = external
        ? new TextureLoader().load(imagepath)
        : new TextureLoader().load(`assets/img/${imagepath}`)
      mesh.setTexture(imgtex)
    },
    onGltfLoad(object) {
      object.traverse(function(child) {
        if (child.isMesh) {
        }
      })
    },
    enterRoom(target, campos = { x: 0, y: 1.6, z: 0 }, from) {
      console.log('Trying to enter a room', target, campos, from)

      this.checkAuth()
      let authInterval = setInterval(() => {
        if (!this.authChecked) {
          return
        }

        clearInterval(authInterval)
        if (!this.authenticated) {
          console.log('Authentication failed - goodbye!')
          return
        }

        this.currentRoom = target
        this.startRoomPos = campos
        // this.$matomo && this.$matomo.trackPageView(`room${target}`)
        this.showLoading = true
        let lookat = { x: 0, z: 0 }
        let camera = this.$refs.camera.camera
        this.interactive = false
        this.loadEnvironment()
        this.showCursor = target === 2 ? false : true
        this.activateShadows(`room${target}`)

        // remove objects from raytracing
        if (from) {
          this.objlist[from].forEach((r) => {
            this.$refs.renderer.three.removeIntersectObject(
              this.$refs[`room${from}`].$refs[r].mesh
            )
          })
        } else if (this.objlist.length > 0) {
          for (let i = 1; i < this.objlist.length; i++) {
            try {
              this.objlist[i].forEach((r) => {
                this.$refs.renderer.three.removeIntersectObject(
                  this.$refs[`room${i}`].$refs[r].mesh
                )
              })
            } catch (e) {
              console.error(e, i)
            }
          }
        }
        if (this.objlist.length > 0) {
          this.objlist[target].forEach((r) => {
            if (this.$refs[`room${target}`].$refs[r].mesh)
              this.$refs.renderer.three.addIntersectObject(
                this.$refs[`room${target}`].$refs[r].mesh
              )
          })
        }
        //Perform actions based on project and room
        switch (this.projectID) {
          case 'FCN':
            lookat = this.FCNActions(lookat)
            break
          case 'FCA':
            lookat = this.FCAActions(lookat)
            break
          case 'GTC':
            lookat = this.GTCActions(lookat)
            break
        }
        //Move camera to position in room, look at target
        camera.position.set(campos.x, this.userHeight, campos.z)
        const vecB = new Vector3(lookat.x, this.userHeight, lookat.z)
        const vecA = new Vector3(
          camera.position.x,
          this.userHeight,
          camera.position.z
        )
        const vecC = vecA.clone().add(vecB.clone().sub(vecA).multiplyScalar(0.09))
        this.navigateTo(null, vecC, 10)
      }, 100)
    },
    cameraLookAt(target, pos, rotate) {
      const camCtrl = this.$refs.renderer.three.cameraCtrl

      if (target) target = this.$refs[target]
      camCtrl.target.set(
        target ? target.position.x : pos.x,
        target ? target.position.y : pos.y,
        target ? target.position.z : pos.z
      )
      camCtrl.autoRotate = rotate ? true : false
      camCtrl.update()
    },
    exposeObject(event) {
      console.log(event)
    },
    navigateTo(event, point, time = 1200) {
      // console.log("navigateTo event", event);
      // console.log("navigateTo point", point || event.intersect.point);
      // console.log("navigateTo time", time);

      if (!this.authenticated) return
      this.closeAudio('podcastplayer')
      this.interactive = false
      if ((!this.lockCursor && this.dragging <= 0) || this.orbiting) {
        const camCtrl = this.$refs.renderer.three.cameraCtrl
        this.lockCursor = true
        if (event) {
          point = event.intersect.point
          camCtrl.autoRotate = false
          this.orbiting = false
        }
        let camera = this.$refs.camera.camera

        const vecB = new Vector3(point.x, this.userHeight, point.z)
        const vecA = new Vector3(
          camera.position.x,
          this.userHeight,
          camera.position.z
        )

        const vecC = vecA
          .clone()
          .add(vecB.clone().sub(vecA).multiplyScalar(0.99))

        var tween = new TWEEN.Tween(camera.position).to(vecC, time)
        camCtrl.target.set(vecB.x, vecB.y, vecB.z)
        tween.onUpdate((object, elapsed) => {
          camera.position.set(object.x, object.y, object.z)
        })
        tween.easing(TWEEN.Easing.Cubic.Out)
        tween.onComplete(() => {
          this.lockCursor = false
        })
        tween.start()
      }
    },
    moveCursor(event) {
      if (!this.lockCursor && this.showCursor && this.authenticated) {
        this.$refs.cursor.mesh.position.set(
          event.intersect.point.x,
          event.intersect.point.y + 0.01,
          event.intersect.point.z
        )
        this.cursorpos = {
          x: event.intersect.point.x,
          y: event.intersect.point.y + 0.01,
          z: event.intersect.point.z
        }
      }
    },
    closeVideo(ref, video) {
      this.$refs[ref].muted = true
      this.$store.commit(`room${this.currentRoom}/shown${video}`)
    },
    startGeneralVideo() {
      if (!this.authenticated) return
      this.$refs.generalvideo.play()
      this.$refs.generalvideo.currentTime = this.$store.state.index.generalTime
    },
    closeGeneralVideo() {
      this.$refs.generalvideo.pause()
      this.$store.commit('index/closeVideo')
    },
    onClick(event, ref) {
      if (!this.authenticated) {
        alert('Nicht angemeldet oder Aktion nicht zugelassen!')
        return
      }

      const componentData = this.roomDataFor(ref)
      const componentType = componentData?.type
      console.log('Executing action for ' + ref, componentType, componentData)

      switch (componentType) {
        case 'video':
          const videoSrc = componentData.content[0].src
          this.playVideo(videoSrc)
          break
        case 'gallery':
          this.openGeneralGallery(componentData)
          break
        case 'gridd':
          this.openGeneralFilegrid(componentData)
          break
        case 'gridv':
          this.openGeneralVideogrid(componentData)
          break
        case 'external':
          this.openGeneralIFrame(componentData)
          break
        case 'webinars':
          this.openOverlay('webinars')
          break;
        default:
          console.log('No action assigned', event, componentData, componentType)
      }
    },
    playVideo(videoSrc, videoTex, postEvent) {
      if (!videoSrc) {
        console.log('No video source found!')
        return
      }

      // Fix for relative paths
      if (!videoSrc.startsWith('http')) {
        videoSrc = import.meta.env.VITE_AXIOS_BASEURL + '/' + videoSrc
      }

      if (videoSrc.startsWith(import.meta.env.VITE_AXIOS_BASEURL)) {
        const videoTime = videoTex ? this.$refs[videoTex].currentTime : 0
        this.$store.commit('index/openVideo', {
          src: videoSrc,
          time: videoTime,
          evt: postEvent
        })
      } else {
        this.$store.commit('index/openIFrame', {
          src: { content: [{ src: videoSrc }] }
        })
      }
    },
    playAudio(ref, audio) {
      if (!this.authenticated) return
      this.$refs[ref].src = audio
      this.$refs[ref].play()
    },
    closeAudio(ref) {
      this.$refs[ref].pause()
    },
    openGeneralGallery(gallery) {
      this.$store.commit('index/openGallery', {
        src: this.roomDataFor(gallery)
      })
    },
    closeGeneralGallery() {
      this.$store.commit('index/closeGallery')
    },
    openGeneralPhotogrid(grid) {
      this.$store.commit('index/openPhotogrid', {
        src: this.roomDataFor(grid)
      })
    },
    closeGeneralPhotogrid() {
      this.$store.commit('index/closePhotogrid')
    },
    openGeneralFilegrid(grid) {
      this.$store.commit('index/openFilegrid', {
        src: this.roomDataFor(grid)
      })
    },
    closeGeneralFilegrid() {
      this.$store.commit('index/closeFilegrid')
    },
    openGeneralIFrame(iframe) {
      this.$store.commit('index/openIFrame', {
        src: this.roomDataFor(iframe)
      })
    },
    roomDataFor(ref) {
      // Chapter2 Checking for the deprecated version of using a ref name
      const isRefName = typeof ref === 'string'
      return isRefName
        ? this.$store.state[`room${this.currentRoom}`].roomData[ref]
        : ref
    },
    closeGeneralIFrame() {
      this.$store.commit('index/closeIFrame')
    },
    openGeneralImpressum() {
      this.$store.commit('index/openImpressum')
    },
    closeGeneralImpressum() {
      this.$store.commit('index/closeImpressum')
    },
    openGeneralVideogrid(grid) {
      this.$store.commit('index/openVideogrid', {
        src: this.roomDataFor(grid)
      })
    },
    closeGeneralVideogrid() {
      this.$store.commit('index/closeVideogrid')
    },
    openOverlay(target) {
      if (!this.authenticated) return

      // Chapter2 - Added uppercase first letter to enforce store mutation camel case
      target = target.charAt(0).toUpperCase() + target.slice(1)
      this.$store.commit(`room${this.currentRoom}/open${target}`)
    },
    closeOverlay(target) {
      this.$store.commit(`room${this.currentRoom}/close${target}`)
    },
    externalLink(link, newtab = false) {
      if (!this.authenticated) return
      window.open(link, newtab ? '_blank' : '_self', 'noopener,noreferrer')
    },
    activateShadows(ref) {
      if (this.quality !== 'low') {
        this.$refs[ref].$refs.container.o3d.children.forEach((n) => {
          if (n.isMesh) {
            n.castShadow = true
            n.receiveShadow = true
            if (n.material.map) n.material.map.anisotropy = 16
          } else {
            n.children.forEach((m) => {
              if (m.isMesh) {
                m.castShadow = true
                m.receiveShadow = true
                if (m.material.map) m.material.map.anisotropy = 16
              } else {
                m.children.forEach((o) => {
                  if (o.isMesh) {
                    o.castShadow = true
                    o.receiveShadow = true
                    if (o.material.map) o.material.map.anisotropy = 16
                  }
                })
              }
            })
          }
        })
      }
    },
    showAgenda() {
      switch (this.currentRoom) {
        case 1:
          this.loadVideoTexture(
            this.$refs[`room${this.currentRoom}`].$refs.aboutscreen,
            this.$refs.aboutvideo
          )
          setTimeout(() => {
            if (this.currentRoom === 1) {
              this.loadImageTexture(
                this.$refs[`room${this.currentRoom}`].$refs.aboutscreen,
                this.agendaimg,
                true
              )
              setTimeout(() => {
                this.showAgenda()
              }, 10000)
            }
          }, 10000)
          break
      }
    },
    GTCActions(lookat) {
      switch (this.currentRoom) {
        case 1:
          let gtcActionInterval = setInterval(() => {
            const roomData = this.$store.state[`room${this.currentRoom}`].roomData
            if (!roomData) {
              return
            }

            clearInterval(gtcActionInterval)
            const lobbyScreenMediaData = this.roomDataFor('lobby_screen_media')
            let lobbyMediaSrc = lobbyScreenMediaData?.content[0].src

            if (lobbyMediaSrc.indexOf('http') !== 0) {
              lobbyMediaSrc = import.meta.env.VITE_AXIOS_BASEURL + '/' + lobbyMediaSrc
            }

            this.$refs.gtcvideo.src = lobbyMediaSrc

            this.loadVideoTexture(
              this.$refs[`room${this.currentRoom}`].$refs.lobby_screen,
              this.$refs.gtcvideo
            )
            this.$refs.gtcvideo.play()
          }, 100)
          break
      }

      return lookat
    },
    FCNActions(lookat) {
      switch (this.currentRoom) {
        case 1:
          this.$store.commit('room1/loadIntroVideo')
          this.loadImageTexture(
            this.$refs[`room${this.currentRoom}`].$refs.feedbackimage,
            this.$cmsFile(
              this.$store.state.room1.roomData?.thumbnail_feedback.content[0]
                .src
            ),
            true
          )
          this.loadVideoTexture(
            this.$refs[`room${this.currentRoom}`].$refs.aboutscreen,
            this.$refs.aboutvideo
          )
          this.$refs.aboutvideo.play()
          lookat = { x: 0, z: -1 }
          axios
            .get(`${import.meta.env.VITE_AXIOS_BASEURL}/api/agenda`)
            .then((res) => {
              this.agendaimg = this.$cmsFile(res.data)
            })
          this.showAgenda()
          break
        case 2:
          this.$store.commit('room2/loadIntroVideo')
          if (this.$store.state.room2.showIntro) {
            this.playVideo(
              this.$cmsFile(
                this.$store.state.room2.roomData?.intro_video.content[0].src
              )
            )
            this.$store.commit('room2/shownIntroVideo')
          }
          lookat = { x: -1, z: 5 }
          break
        case 3:
          const imgtex = new TextureLoader().load(
            this.$cmsFile(
              this.$store.state.room3.roomData?.thumbnail_front_screen
                .content[0].src
            )
          )
          imgtex.flipY = false
          this.$refs[
            `room${this.currentRoom}`
            ].$refs.r3_introductionscreen.o3d.children[0].material.map = imgtex
          this.loadImageTexture(
            this.$refs[`room${this.currentRoom}`].$refs.r3_livewebinarscreen,
            this.$cmsFile(
              this.$store.state.room3.roomData?.thumbnail_video_livestreaming
                .content[0].src
            ),
            true
          )
          this.loadImageTexture(
            this.$refs[`room${this.currentRoom}`].$refs.r3_workshopscreen,
            this.$cmsFile(
              this.$store.state.room3.roomData?.thumbnail_digitale_etikette
                .content[0].src
            ),
            true
          )
          this.loadImageTexture(
            this.$refs[`room${this.currentRoom}`].$refs.r3_webcastscreen,
            this.$cmsFile(
              this.$store.state.room3.roomData?.thumbnail_livestream_webcast
                .content[0].src
            ),
            true
          )
          this.loadImageTexture(
            this.$refs[`room${this.currentRoom}`].$refs.r3_zoomscreen,
            this.$cmsFile(
              this.$store.state.room3.roomData?.thumbnail_zoom.content[0].src
            ),
            true
          )
          break
        case 5:
          this.loadImageTexture(
            this.$refs[`room${this.currentRoom}`].$refs.blendingintroscreen,
            this.$cmsFile(
              this.$store.state.room5.roomData?.thumbnail_video_online_trainings
                .content[0].src
            ),
            true
          )
          this.loadImageTexture(
            this.$refs[`room${this.currentRoom}`].$refs.r5_elearningscreen,
            this.$cmsFile(
              this.$store.state.room5.roomData?.thumbnail_elearning.content[0]
                .src
            ),
            true
          )
          this.loadImageTexture(
            this.$refs[`room${this.currentRoom}`].$refs.r5_pastascreen,
            this.$cmsFile(
              this.$store.state.room5.roomData?.thumbnail_pasta.content[0].src
            ),
            true
          )
          break
        case 6:
          this.$store.commit('room6/loadIntroVideo')
          if (this.$store.state.room6.showIntro) {
            this.playVideo(
              this.$cmsFile(
                this.$store.state.room6.roomData?.intro_video.content[0].src
              )
            )
            this.$store.commit('room6/shownIntroVideo')
          }
          this.loadImageTexture(
            this.$refs[`room${this.currentRoom}`].$refs.r6_gallery_thumbnail,
            this.$cmsFile(
              this.$store.state.room6.roomData?.thumbnail_gallery.content[0].src
            ),
            true
          )
          this.loadImageTexture(
            this.$refs[`room${this.currentRoom}`].$refs.r6_video_thumbnail,
            this.$cmsFile(
              this.$store.state.room6.roomData?.thumbnail_video.content[0].src
            ),
            true
          )
          this.loadImageTexture(
            this.$refs[`room${this.currentRoom}`].$refs.r6_communicationsscreen,
            this.$cmsFile(
              this.$store.state.room6.roomData?.thumbnail_communications
                .content[0].src
            ),
            true
          )
          break
      }
      return lookat
    },
    FCAActions(lookat) {
      switch (this.currentRoom) {
        case 1:
          lookat = { x: 0, z: -1 }
          this.$store.commit('room1/hideIntro')
          /* this.loadVideoTexture(
            this.$refs[`room${this.currentRoom}`].$refs.aboutscreen,
            this.$refs.aboutvideo
          )
          this.$refs.aboutvideo.play() */
          break
        case 2:
          lookat = { x: 0, z: -1 }
          this.$store.commit('room1/hideIntro')
          break
      }
      return lookat
    }
  }
}
</script>

<style>
body {
  margin: 0;
}

canvas {
  display: block;
}

.asset {
  @apply hidden;
}

.fcn-btn {
  transition: all 2s;
  @apply w-64 h-12 text-center m-12 mb-2 cursor-pointer;
}

.intro {
  @apply absolute w-screen h-screen flex flex-col justify-center items-center text-center z-30 bg-white bg-opacity-90;
}

.overlay {
  @apply absolute w-screen h-screen flex flex-col justify-center items-center text-center z-20 bg-white bg-opacity-90;
}

.home-enter-active,
.home-leave-active {
  transition: all 0.5s;
}

.home-enter,
.home-leave-active {
  opacity: 0;
}
</style>
