export default {
  install: (app) => {
    function cmsModel(filename, ext = 'glb' ) {
      return `${import.meta.env.VITE_AXIOS_BASEURL}/models/${
        import.meta.env.VITE_PROJECT_ID
      }/${filename}/${filename}.${ext}`
    }

    function cmsRoom(room_id, suffix = '', ext = 'gltf', compression = null) {
      const baseUrl = import.meta.env.VITE_AXIOS_BASEURL
      const projectId = import.meta.env.VITE_PROJECT_ID

      const gltfPath = `${baseUrl}/models/${projectId}/room_${room_id}/`
      const gltfFile = `room_${room_id}${suffix}.${ext}`

      return gltfPath + gltfFile

      return `${import.meta.env.VITE_AXIOS_BASEURL}/models/${
        import.meta.env.VITE_PROJECT_ID
      }/room_${room_id}/room_${room_id}.${ext}`
    }

    app.config.globalProperties.$cmsModel = cmsModel
    app.config.globalProperties.$cmsRoom = cmsRoom
  },
}
