<template>
  <div class="flex justify-center mt-20 cursor-pointer overflow-scroll">
    <div class="photooverlay" v-if="showPhotodetail">
      <div
        :class="[
          photoHeight(itemSelected),
          covermode
            ? 'bg-cover bg-center bg-opacity-75'
            : 'bg-contain bg-center bg-opacity-85',
        ]"
        class="
          w-2/3
          relative
          justify-center
          bg-white
          z-20
          md:w-3/12
          bg-no-repeat
        "
        :style="{
          'background-image': 'url(' + $cmsFile(itemSelected?.src) + ')',
          'background-position-y': itemSelected.options?.y_margin || '15%',
        }"
      >
        <ExitBtn @exit="closeshowPhotodetail" v-bind:topSpace="false" />
      </div>
      <div
        :class="[box_heigt(itemSelected)]"
        class="
          w-2/3
          relative
          md:w-3/12
          text-center
          bg-fcn-blue-dark
          text-white
          textoverlay
        "
      >
        <div
          :class="[itemSelected.options?.subtitle ? '' : 'pb-4']"
          class="text-left text-2xl pt-4 pl-3"
          v-text="itemSelected.options?.title"
        ></div>
        <div
          v-if="itemSelected.options?.subtitle"
          class="text-left text-xl pt-1 pl-3 pb-4"
          v-text="itemSelected.options.subtitle"
        ></div>
        <ul
          v-if="itemSelected.options?.list.length"
          class="list text-left px-3 pb-4 ml-4"
        >
          <li v-for="listpoint in itemSelected.options.list" :key="listpoint">
            {{ listpoint }}
          </li>
        </ul>
        <div
          v-if="itemSelected.options?.text"
          class="text-left text-base px-3"
          v-html="itemSelected.options?.text"
        ></div>
      </div>
    </div>
    <div class="grid gap-x-5 gap-y-3 grid-cols-3">
      <div
        v-for="(item, i) in content"
        @click="openshowPhotodetail(item)"
        :class="[
          covermode ? 'bg-cover bg-top' : 'bg-contain bg-center',
          padding ? 'p-2' : '',
        ]"
        :key="`photo${i}`"
        :index="i"
        :style="backgroundStyle(item)"
        class="
          border-3 border-fcn-orange-light
          rounded-lg
          h-60
          w-52
          bg-no-repeat
        "
      >
        <div
          :class="[covermode ? 'bg-cover bg-top' : 'bg-contain bg-center']"
          class="w-full h-full bg-no-repeat"
          :style="{
            'background-image': 'url(' + $cmsFile(item.src) + ')',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPhotodetail: false,
      itemSelected: {},
    }
  },
  props: {
    content: {
      type: Array,
      default() {
        return []
      },
    },
    covermode: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    box_heigt(item) {
      if (
        item.options?.text || item.options?.list.length !== 0
      ) {
        return 'h-96 overflow-auto'
      } else if (item?.subtitle) {
        return 'h-28 overflow-hidden'
      } else {
        return 'h-14 overflow-hidden'
      }
    },
    photoHeight(item) {
      return item.options?.text ||
        (item.options?.list && item.options?.list.length != 0)
        ? 'h-80'
        : 'h-96'
    },
    openshowPhotodetail(item) {
      this.itemSelected = item
      this.showPhotodetail = true
    },
    closeshowPhotodetail() {
      this.showPhotodetail = false
    },
    backgroundStyle(item) {
      let style = {}
      if (item.options?.bg_color) {
        style['background-color'] = item.options.bg_color
      }

      return style
    },
  },
}
</script>

<style>
.textoverlay {
  @apply absolute w-screen h-screen z-20 bg-white bg-opacity-75;
}
.photooverlay {
  @apply fixed top-0 w-screen h-screen flex flex-col justify-center items-center text-center z-20 bg-opacity-75;
}

.list {
  list-style-type: none;
  list-style-position: inside;
}

.list li::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: white; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1rem;
}
</style>
