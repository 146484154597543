export default {
  install: (app) => {
    function cmsRoomNav(room_id, name = 'navmesh', ext = 'glb') {
      const baseUrl = import.meta.env.VITE_AXIOS_BASEURL
      const project = import.meta.env.VITE_PROJECT_ID

      const gltfPath = `${baseUrl}/models/${project}/room_${room_id}/`
      const gltfFile = `${name}.${ext}`

      return gltfPath + gltfFile
    }

    app.config.globalProperties.$cmsRoomNav = cmsRoomNav
  }
}
