export default {
  state: () => {
    return {
      roomData: null,
      showIntro: true,
      showAboutVideo: false,
      showContacts: false,
      showClients: false,
      showPDFDownload: false,
      showGallery: false,
      showExitDialog: false,
      showFeedback: false,
      showWebinars: false,
    }
  },
  mutations: {
    setRoomData(state, data) {
      state.roomData = data
    },
    loadIntroVideo(state) {
      const res = localStorage.getItem('room1_introvideo')
      state.showIntro = res !== 'false'
    },
    hideIntro(state) {
      state.showIntro = false
    },
    shownIntroVideo(state) {
      state.showIntro = false
      localStorage.setItem('room1_introvideo', state.showIntro)
    },
    shownAboutVideo(state) {
      state.showAboutVideo = false
    },
    openIntroVideo(state) {
      state.showVideo = true
    },
    openAboutVideo(state) {
      state.showAboutVideo = true
    },
    openshowContacts(state) {
      state.showContacts = true
    },
    openshowClients(state) {
      state.showClients = true
    },
    openshowGallery(state) {
      state.showGallery = true
    },
    closeshowContacts(state) {
      state.showContacts = false
    },
    closeshowClients(state) {
      state.showClients = false
    },
    closeshowGallery(state) {
      state.showGallery = false
    },
    openshowPDFDownload(state) {
      state.showPDFDownload = true
    },
    closeshowPDFDownload(state) {
      state.showPDFDownload = false
    },
    openshowExitDialog(state) {
      state.showExitDialog = true
    },
    closeshowExitDialog(state) {
      state.showExitDialog = false
    },
    openshowFeedback(state) {
      state.showFeedback = true
    },
    closeshowFeedback(state) {
      state.showFeedback = false
    },
    openWebinars(state) {
      state.showWebinars = true
    },
    closeWebinars(state) {
      state.showWebinars = false
    }
  },
  actions: {},
  getters: {
    setting(ref) {
      console.log()
    }
  },
}
