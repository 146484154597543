<template>
  <div>
    <a href='https://www.gastro-teeamcoaching.de'
       class='contact-btn fixed left-10 bottom-10 z-50'
       target='_blank'
       style='width: auto;padding: 0 0.5em'
    >
      Verlassen
    </a>
    <button
      v-if='!openButton && !chat'
      class='contact-btn fixed bottom-10 right-10 z-50'
      @click='showButton()'
    >
      <img class='ml-3' src='/assets/img/icons/chatbubble.svg' />
      <p class='ml-4'>Kontakt</p>
    </button>
    <div
      v-if='openButton && !chat'
      class='flex flex-col fixed bottom-10 right-10 space-y-4 z-50'
    >
      <div class='contact-btn'>
        <a @click='toggleChat()' class='ml-5 flex cursor-pointer'>
          <img src='/assets/img/icons/chatbubble.svg' />
          <div class='ml-5'>Chat</div>
        </a>
      </div>
      <div class='contact-btn'>
        <a
          href='mailto:info@frontcooking-academy.de?subject=Kontaktanfrage'
          class='ml-5 flex'
        >
          <img src='/assets/img/icons/mail.svg' />
          <div class='ml-5'>Mail</div>
        </a>
      </div>
      <div class='contact-btn'>
        <a href='tel:004915129100835' class='ml-4 flex'>
          <img src='/assets/img/icons/phone.svg' />
          <div class='ml-4'>Telefon</div>
        </a>
      </div>
    </div>
    <button
      v-if='chat'
      class='contact-btn fixed bottom-10 right-10 z-50'
      @click='toggleChat()'
    >
      <img class='ml-3' src='/assets/img/icons/chatbubble.svg' />
      <p class='ml-4'>Schließen</p>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openButton: false,
      chat: false
    }
  },
  methods: {
    showButton() {
      this.openButton = true
      setTimeout(() => {
        this.openButton = false
      }, 10000)
    },
    toggleChat() {
      window.$chatwoot.toggle()
      this.chat = !this.chat
    }
  }
}
</script>

<style>
.contact-btn {
  @apply flex
  w-40
  h-12
  bg-fcn-blue-dark
  text-white text-xl
  rounded
  items-center;
}

.woot--bubble-holder {
  @apply hidden;
}
</style>
